// .hexagon {
//   margin-top: 30px;
// padding: 30px;
//   height: 300px;
//   width: 300px;
//   text-align: center;
//   border-radius: 15px 50px;
//   border: solid#202631;
//   background-color: #374659;
//   // background-color: #B77333;
// }

#distributorAssemblies-pichome {
  height: 200px;
  width: 200px;
  border-radius: 15px 50px;
  border:solid grey

}
.typewriter {
  color:  #374659;
}

#distributorLink {
  padding: 10px;
  background-color: black;
}

article {
 margin-bottom: 30px;
}

.box {
  // background-color: #374659;
  // background-color: #B77333;
  background-color: black;
}
.text-footer {
  color: #B77333;
  // border-right: 5px solid #B77333;
  border-bottom: 5px solid #B77333;
}
.text-fabricate {
  color: #B77333;
  font-style: italic;
  // border-right: 5px solid #B77333;
}

.explore {
  padding: 15px;
  color: #B77333;
  // border-right: 5px solid #B77333;
  border-bottom: 5px solid #B77333;
}

.visit {
  padding: 15px;
  color: #B77333;
  // border-right: 5px solid #B77333;
  border-bottom: 5px solid #B77333;
  font-style: italic;
}

.address {
  color: #B77333;
  font-style: italic;
}

.navList {
  color: #B77333;
  border-right: 5px solid #B77333;
}
span {
  color: #B77333;
  text-size-adjust: 10px;
}

.intro {
  background-color: rgba(8, 8, 8, 0.8);
  letter-spacing: .15em;
}
.intro-button {
  color: black;
}