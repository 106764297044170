body {
  background-color: black;
  color: white;
}


.products {
  margin-top: 40px;
}


.product-intro {
  float: left;
  padding: 10px;
}

.dashCard {
  border: solid #374659;
  padding: 10px;
  margin: 2px;
  margin-bottom: 15px;
  white-space: nowrap;
  max-width: 100%;
  word-wrap: break-word;
  flex-wrap: wrap
}

.column-6 {
  text-align: center;
}

@media (max-width: 768px) { 
  #headerpic {
    flex-direction: column;
  }
}

#headerpic {
  max-width:100%;
  height:auto;
  text-align: center;
  margin:auto;
  display: block;
  margin-left: auto;
  margin-right: auto;
  // width: 50%;
  // width: 900px;
  // margin-left: 130px; 
  // margin-bottom: 85px;
  border: solid #374659 10px; 
  // overflow-y: scroll;
  white-space: nowrap;
 // max-width: 100%;
}
.link-title {
  word-wrap: break-word;
}
.product-intro {
  margin: auto;
  padding: 25px;
  background-color: rgba(8, 8, 8, 0.8);
  letter-spacing: .15em;
  text-align: center;
}
.product-cards {
  padding: 5px;
  margin: 2px 2px 2px 2px;
  flex-wrap: wrap;
  text-align: center;
  word-wrap: break-word;
}
@media (max-width: 768px) { 
  .product-cards {
    flex-direction: column;
  }
}