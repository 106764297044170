.TubeFabrication {
  background-color: black;
}

.spunProduct-pic {
  height: auto;
  width: 350px;
 padding: 50px;
  max-width: 100%;
  margin-bottom: 10px;
}
.fabricationText {
  margin: auto;
  padding: 25px;
  background-color: rgba(8, 8, 8, 0.8);
  letter-spacing: .15em;
  text-align: center;

}

.title{
  text-align: center;
}
.home-return {
  color: #374659;
  clear:left;
  margin: 20px;

}

.product-return {
  color: #374659;
  margin: 20px;
  clear:left;
}

.quote-request {
  color: #374659;
  margin: 20px;
  clear:left;
}