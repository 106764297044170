.box {
  // background-color: #374659;
  // background-color: #B77333;
  background-color: black;
}
.text-footer {
  color: #B77333;
  // border-right: 5px solid #B77333;
  border-bottom: 5px solid #B77333;
}
.text-fabricate {
  color: #B77333;
  font-style: italic;
  // border-right: 5px solid #B77333;
}

.explore {
  padding: 15px;
  color: #B77333;
  // border-right: 5px solid #B77333;
  border-bottom: 5px solid #B77333;
}

.visit {
  padding: 15px;
  color: #B77333;
  // border-right: 5px solid #B77333;
  border-bottom: 5px solid #B77333;
  font-style: italic;
}

.address {
  color: #B77333;
  font-style: italic;
}

.navList {
  color: #B77333;
  border-right: 5px solid #B77333;
}
.favicon {
  width: 1095px;
  height: 25px;
}

.nameTag {
  text-align: center;
  font-family: cursive;
  font-style: italic;
}
.social-media{
  background-color:#374659 ;
  text-align: center;
}
.social-pic {
  // text-align: center;
  flex-direction: row;
  display: grid;
  height: 2em;
  width: 2em;
  padding: 2px;
  margin-right: auto;
  margin-left: auto;
}