body.aboutUs {
   background-color: white;
}
.pic-header {
  left: 0;
  right: 0;
  text-align: center;
  margin: auto;
  border: solid #374659 1px;
}

.header-pic {
  width: 240px;
  height: 240px;
  // border-radius: 15px 50px;
  border:solid white;
  margin-bottom: 85px;
  border: solid #374659 10px; 

}
.p-2 {
  border: solid #374659 10px; 
}

.about-us-text {
  margin: auto;
  padding: 25px;
  background-color: rgba(8, 8, 8, 0.8);
  letter-spacing: .15em;
  text-align: center;
}

.Location-container-fluid {
  
  background-color: #374659;
  text-align: center;

}

// .location-pics-container {
//   // width: 300px;
//   // height: 300px;
//   // background-color: #374659;
//   // width: 0px;
//   // flex-grow: 1;

// }
.clarksville-pic {
  float: left;
  margin: auto;
  // margin-right: 100px;
  border: white 10px solid;
  // margin-left: 100px;
  max-width: 100%;
  
}

.chipley-pic {
  float: right;
  margin: auto;
  // margin-left: 100px;
  border: white 10px solid;
  max-width: 100%;
}
.favicon-pic {

  border: white 10px solid;
}

.locationMap {
  margin-top: 125px;
  padding: 5px;
  margin: auto;
  margin-top: 25px;
  max-width: 100%;
  
}

