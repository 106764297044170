@import '~bootstrap/dist/css/bootstrap.min.css';
@import url('https://fonts.googleapis.com/css2?family=Arvo&display=swap');
@import url('https://fonts.googleapis.com/css2?family=MuseoModerno&display=swap');


html,body
{
  scroll-padding-top: 70px;
    width: 100%;
    height: 100%;
    // margin: 0px;
    padding: 0px;
    overflow-x: hidden; 
}

body {
  // padding-top: 40px;
  
  overflow-x: hidden;
  margin: 0;
  width: auto;
  font-family: -apple-system, BlinkMacSystemFont, 'Arvo','Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: black;
  background: url('https://images.unsplash.com/photo-1584774354932-62ceb99e6053?ixid=MXwxMjA3fDB8MHxzZWFyY2h8NXx8Y29wcGVyJTIwcGlwZXxlbnwwfHwwfA%3D%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=500&q=60') center no-repeat;
  // background: url("https://live.staticflickr.com/65535/50912297741_aeb2ec0f5a_t.jpg")center no-repeat;
  background-size: cover;
  // padding: 4rem 2rem;
  height: 600px;
  // background: url("https://live.staticflickr.com/65535/50976866137_aa60bc69ae_t.jpg")center no-repeat;
  ;
  // background-color: #2e3F53;
}
/* Media Queries: Tablet Landscape */
// @media screen and (max-width: 1060px) {
//   #primary { width:67%; }
//   #secondary { width:30%; margin-left:3%;}  
// }

// /* Media Queries: Tabled Portrait */
// @media screen and (max-width: 768px) {
//   #primary { width:100%; }
//   #secondary { width:100%; margin:0; border:none; }
// }
code {
  font-family: Arvo, source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.home{
  margin-top: 10px;
  margin-left: 10px;
  margin-right: 10px;
}
h1{
  
  text-align: center;
  color:  #B77333;
}

.title {
  text-align: center;
}
// .home-title {
//   animation-duration: 8s;
//   animation-name: slidein;
//   animation-iteration-count: infinite;
// //   width: 50px;
// // height: 50px;
// border-radius: 12px;
// }

// @keyframes slidein {
// 0% {
//   margin-left: 0%;
// }
// 50% {
//   margin-left: 200px;
// }
// 100% {
//   margin-left: 0%;
// }
// }

.heroarea {
  /* Photo by mnm.all on Unsplash */
  // background: url('https://live.staticflickr.com/65535/50912428157_7e46378347_z.jpg" width="359" height="640" alt="straight') center;
  // background: url('https://images.unsplash.com/photo-1548357204-82fc6c4a0c67?ixid=MXwxMjA3fDB8MHxzZWFyY2h8MXx8Y29wcGVyfGVufDB8fDB8&ixlib=rb-1.2.1&auto=format&fit=crop&w=500&q=60') center;
  // background: url('https://live.staticflickr.com/65535/50912297021_006ab43033_t.jpg" width="100" height="31" alt="Collage-for-home-screen-1') center;
  // background: url('https://images.unsplash.com/photo-1584774354932-62ceb99e6053?ixid=MXwxMjA3fDB8MHxzZWFyY2h8NXx8Y29wcGVyJTIwcGlwZXxlbnwwfHwwfA%3D%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=500&q=60') center;
  // background-size: cover;
  padding: 4rem 2rem;
  height: 600px;

  /* Grid styles */
  display: grid;
  align-items: center;
  grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
}
.header {
  background-size: cover;
  box-shadow: 5px 5px 5px 5px black; 
  align-items: center;
  width: auto;
  border-radius: 12px;
  padding: 10px 10px 40px 10px;
}

button {
  background-color: #808080;
  border: none;
  color: white;
  padding: 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 12px;
  margin: 4px 2px;
  border-radius: 12px;
}

.intro {
  // background-color: #808080;
  color: white;
}

.typewriter {
  overflow: hidden; /* Ensures the content is not revealed until the animation */
  border-right: .15em solid #ae7353; /* The typwriter cursor */
  white-space: wrap; /* unwrap Keeps the content on a single line */
  margin: 0 auto; /* Gives that scrolling effect as the typing happens */
  letter-spacing: .15em; /* Adjust as needed */
  background-color: rgba(8, 8, 8, 0.8);
  animation: 
    typing 3.5s steps(40, end),
    blink-caret .75s step-end infinite;
    color: white;
}

/* The typing effect */
@keyframes typing {
  from { width: 0 }
  to { width: 100% }
}

/* The typewriter cursor effect */
@keyframes blink-caret {
  from, to { border-color: transparent }
  50% { border-color: #ae7353; }
}
body.aboutUs {
  background-color: white;
}